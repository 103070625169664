import DoneReportModal from "@components/molecules/done-report-modal";
import ReportOptions from "@components/molecules/report-options";
import BaseModal from "@components/atoms/base-modal";
import Button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import classNames from "classnames";
import { useState } from "react";

interface Props {
  memoId: number;
  open: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

export function useReportMemoModal(props: Props) {
  const [submit, setSubmit] = useState(false);
  const [comment, _setComment] = useState("");
  const [reason, _setReason] = useState("");
  const [done, setDone] = useState(false);
  const [tab, _setTab] = useState("");

  const close = () => {
    _setReason("");
    _setTab("");
    _setComment("");
    setSubmit(false);
    setDone(false);

    props?.onClose?.();
  };

  const setReason = (value: string) => {
    _setReason(value);
    setSubmit(true);
  };

  const setTab = (value: string) => {
    setSubmit(false);
    _setTab(value);
  };

  const setComment = (value: string) => {
    setSubmit(value.trim().length > 0);
    _setComment(value);
  };

  const report = () => {
    if (submit) {
      // sdk here
      setDone(true);
    }
  };

  return {
    model: {
      tab,
      done,
      reason,
      comment,
      submit,
    },
    operations: {
      close,
      setTab,
      report,
      setReason,
      setComment,
    },
  };
}

export default function ReportMemoModal(props: Props) {
  const { model, operations } = useReportMemoModal(props);

  return (
    <BaseModal open={props.open}>
      <div
        className={classNames({
          "bg-memod-base w-96 h-[460px] py-4 px-6 flex flex-col !pb-8":
            model.tab === "" && !model.done,
          "bg-memod-base w-96 h-[400px] py-4 px-6 flex flex-col !pb-8":
            model.tab !== "" && !model.done,
          "bg-memod-base w-96 h-[350px] py-4 px-6 flex flex-col !pb-8":
            model.done,
        })}
      >
        {model.done ? (
          <DoneReportModal onClose={operations.close} />
        ) : (
          <>
            <div className="w-full flex flex-row justify-end">
              <button className="justify-self-end" onClick={operations.close}>
                <Icon.Close className="text-white" />
              </button>
            </div>

            <div className="flex flex-col justify-center items-center mb-8">
              <h3 className="text-2xl text-memod-white-base font-semibold">
                {translate("base.report")}
              </h3>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-col w-10/12 my-4 items-center justify-center mx-auto">
                {model.tab === "" && (
                  <ReportOptions
                    reason={model.reason}
                    onReason={(value) => operations.setReason(value)}
                    onTab={(value) => operations.setTab(value)}
                  />
                )}

                {model.tab === "1" && (
                  <>
                    <span className="text-center text-memod-white-secondary mb-5">
                      {translate("report.other-reason")}
                    </span>
                    <textarea
                      className="rounded-md bg-memod-button p-2 text-base-white focus:border-none"
                      onChange={(e) => operations.setComment(e.target.value)}
                      placeholder={translate("report.tell-us")}
                      cols={40}
                      rows={4}
                    />
                  </>
                )}

                {model.tab === "2" && (
                  <>
                    <span className="text-center text-memod-white-secondary mb-5">
                      {translate("report.copyright-issue")}
                    </span>
                    <textarea
                      className="rounded-md bg-memod-button p-2 text-base-white focus:border-none"
                      onChange={(e) => operations.setComment(e.target.value)}
                      placeholder={translate("report.tell-us")}
                      cols={40}
                      rows={4}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col">
              {model.tab === "" && (
                <div className="text-memod-white-base flex flex-col items-center justify-center text-[13px] mt-8">
                  <span>{translate("report.ccp-message")}</span>
                  <a href="#" className="hover:underline text-memod-green-base">
                    {translate("base.ccp")}
                  </a>
                </div>
              )}

              <div className="flex flex-col items-center justify-center mt-5">
                <Button.Pill
                  onClick={operations.report}
                  className={classNames({
                    "!bg-memod-green-base/60 !text-gray-400 cursor-not-allowed":
                      !model.submit,
                    "!bg-memod-green-base !text-base-white": model.submit,
                  })}
                >
                  {translate("base.submit")}
                </Button.Pill>
              </div>
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}
