import { MemoInterface, WriterInterface } from "@carglassgithub/memod-sdk";
import BaseModal from "@components/atoms/base-modal";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import classNames from "classnames";
import Link from "next/link";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

interface Props {
  open: boolean;
  memo: MemoInterface;
  writer: WriterInterface;
  onClose?: () => void;
}

export function useShareCardModal(props: Props) {
  const { writer } = props;
  const copyLink = () => {
    if (navigator) {
      navigator.clipboard.writeText(
        `${window.location.origin}/${writer.displayname}/${props.memo.slug}-${props.memo.id}`
      );
      props.onClose?.();
    }
  };

  const Url = `${window.location.origin}/${writer.displayname}/${props.memo.slug}-${props.memo.id}`;

  const getUrl = () => {
    if (globalThis) {
      return globalThis?.location?.href;
    }
  };

  const shareTwitter = (props: {
    url: string;
    title: string;
    related: string[];
    hashtags: string[];
  }) => {
    const url = new URL("https://twitter.com/share");

    url.searchParams.append("url", props.url);
    url.searchParams.append("text", props.title);
    url.searchParams.append("related", props.related.join(", "));
    url.searchParams.append("hashtags", props.hashtags.join(", "));

    window.open(url.toString(), "_blank");
  };

  return {
    model: {
      url: Url,
      displayname: writer.displayname,
    },
    operations: {
      copyLink,
      shareTwitter,
    },
  };
}

export default function ShareCardModal(props: Props) {
  const { operations, model } = useShareCardModal(props);

  return (
    <BaseModal open={props.open}>
      <div
        className={classNames(
          "flex w-80 sm:w-[485px] flex-col bg-memod-base py-4 !pb-8"
        )}
      >
        <div className="flex w-full flex-row items-center justify-center px-6 py-2">
          <div className="flex w-11/12 justify-center">
            <span className="text-2xl text-base-white">
              {translate("base.share-to")}
            </span>
          </div>

          <div className="flex w-1/12 flex-row justify-end">
            <button className="justify-self-end" onClick={props.onClose}>
              <Icon.Close className="text-white" />
            </button>
          </div>
        </div>

        <div className="flex flex-col py-4">
          <div
            onClick={() =>
              operations.shareTwitter({
                url: model.url!,
                title: props.memo?.title,
                related: [props.memo.writer?.displayname],
                hashtags: props.memo.topics?.map?.(({ name }) => name) ?? [],
              })
            }
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Twitter />
            </span>
            <span>{translate("base.share-to")} Twitter</span>
          </div>

          <LinkedinShareButton
            url={model.url!}
            source={model.url!}
            title={props.memo?.title}
            summary={props.memo?.description}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Linkedin />
            </span>
            <span>{translate("base.share-to")} LinkedIn</span>
          </LinkedinShareButton>

          <FacebookShareButton
            url={model.url!}
            title={props.memo?.title}
            quote={props.memo?.description}
            hashtag={
              props.memo?.topics?.map(({ name }) => name).join(", ") ||
              undefined
            }
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span className="ml-1 mr-1">
              <Icon.Facebook />
            </span>
            <span>{translate("base.share-to")} Facebook</span>
          </FacebookShareButton>

          <WhatsappShareButton
            url={model.url!}
            title={props.memo?.title}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Whatsapp />
            </span>
            <span>{translate("base.share-to")} Whatsapp</span>
          </WhatsappShareButton>

          <RedditShareButton
            url={model.url!}
            title={props.memo?.title}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Reddit />
            </span>
            <span>{translate("base.share-to")} Reddit</span>
          </RedditShareButton>

          <EmailShareButton
            url={model.url!}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Email />
            </span>
            <span>{translate("base.share-to")} Email</span>
          </EmailShareButton>

          <Link
            href="#"
            onClick={operations.copyLink}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Link />
            </span>
            <span>{translate("base.copy-link")}</span>
          </Link>
        </div>
      </div>
    </BaseModal>
  );
}
