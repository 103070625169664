import Icon from "@components/atoms/icon";
import { translate } from "@locales";

interface Props {
  onReason: (reason: string) => void;
  onTab: (tab: string) => void;
  reason: string;
}

export default function ReportOptions(props: Props) {
  return (
    <ul style={{ all: "unset" }} className="space-y-6 flex flex-col pb-5">
      <li className="text-memod-white-base">
        <div className="flex flex-row items-center justify-center w-full">
          <label htmlFor="option-1" className="w-11/12 cursor-pointer">
            {translate("report.hateful-title")}
          </label>
          <input
            onChange={(e) => props.onReason(e.target.value)}
            className="w-1/12 cursor-pointer"
            checked={props.reason === "2"}
            style={{ marginLeft: "8px" }}
            id="option-1"
            type="radio"
            value="2"
          ></input>
        </div>
      </li>

      <li className="text-memod-white-base">
        <div className="flex flex-row items-center justify-center w-full">
          <label htmlFor="option-2" className="w-11/12 cursor-pointer">
            {translate("report.vulgar-title")}
          </label>
          <input
            onChange={(e) => props.onReason(e.target.value)}
            checked={props.reason === "1"}
            style={{ marginLeft: "8px" }}
            className="w-1/12 cursor-pointer"
            id="option-2"
            type="radio"
            value="1"
          ></input>
        </div>
      </li>

      <li
        className="text-memod-white-base cursor-pointer"
        onClick={() => props.onTab("1")}
      >
        <div className="flex flex-row w-full justify-between items-center">
          <span>{translate("report.other-reason-title")}</span>
          <Icon.ArrowNext />
        </div>
      </li>

      <li
        className="text-memod-white-base cursor-pointer"
        onClick={() => props.onTab("2")}
      >
        <div className="flex flex-row w-full justify-between items-center">
          <span>{translate("report.plagiarized-title")}</span>
          <Icon.ArrowNext />
        </div>
      </li>
    </ul>
  );
}
