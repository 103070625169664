import MenuArrow from "@components/atoms/icon/menu-arrow";
import { Popover as HP } from "@headlessui/react";
import { UserAtom } from "@models/state";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { PropsWithChildren } from "react";
import Icon from "../icon";

export interface Item {
  text: string;
  Icon?: any;
  key: string | number;
}

interface Props extends PropsWithChildren {
  className?: string;
  panelClassName?: string;
  items?: Item[];
  onItem?: (item: Item) => void;
  position?: "top" | "bottom" | "left";
  onRenderItem?: (item: Item, index: number) => JSX.Element;
  showLoading?: boolean;
  isLoading?: boolean;
}

export default function Popover(props: Props) {
  const { position = "bottom" } = props;
  const container = classNames(
    "flex items-center justify-center select-none outline-none",
    props.className
  );

  const panel = classNames(
    "absolute flex flex-col items-center w-full",
    {
      "left-3 md:top-5": position === "bottom",
      "bottom-10": position === "top",
      "-left-9": position === "left",
    },
    props.panelClassName
  );

  const items = props.items
    ?.sort((item) => (item.key === "save" ? -1 : 1))
    ?.map((item, index) => props.onRenderItem?.(item, index));

  const user = useAtomValue(UserAtom);


  if (user) {
    return (
      <HP className="relative flex-1">
        <HP.Button className={container}>{props.children}</HP.Button>

        <HP.Panel className={panel}>
          {position === "bottom" && (
            <MenuArrow className="-ml-10 text-memod-bullet-hover" />
          )}
          <div className="popover-bar flex max-h-40 min-h-[45px] min-w-[220px] flex-col items-center overflow-auto rounded-[4px] bg-memod-bullet-hover !mr-[130px] sm:mr-0">
            {props.showLoading && props.isLoading && (
              <span className="flex-1 flex items-center -mt-px">
                <Icon.Loading size={32} cssStyles={{ stroke: "#FFFFFF80" }} />
              </span>
            ) || items}
          </div>
          {position === "top" && (
            <MenuArrow className="rotate-180 text-memod-bullet-hover" />
          )}
        </HP.Panel>
      </HP>
    );
  } else {
    return (
      <HP className="relative flex-1">
        <HP.Button className={container}>{props.children}</HP.Button>
      </HP>
    )
  }
}