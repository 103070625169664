import Button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import classNames from "classnames";

interface Props {
  onClose?: () => void;
}

export default function DoneReportModal(props: Props) {
  return (
    <>
      <div className="flex flex-col justify-center items-center mb-8">
        <Icon.Done />
      </div>

      <div className="flex flex-col justify-center items-center mb-8">
        <h3 className="text-xl text-memod-white-base font-semibold">
          {translate("report.done-title")}
        </h3>
      </div>

      <div className="text-memod-white-secondary flex flex-col items-center justify-center text-center">
        <span>{translate("report.done-message")}</span>
      </div>

      <div className="flex flex-col items-center justify-center mt-5">
        <Button.Pill
          onClick={props.onClose}
          className={classNames("!bg-memod-green-base !text-base-white")}
        >
          {translate("base.done")}
        </Button.Pill>
      </div>
    </>
  );
}
