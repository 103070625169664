import { useMemo, useReducer, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useAtomValue, useStore } from "jotai";
import classNames from "classnames";
import { toast } from "react-toastify";
import Cookies from "cookie-universal";
import { MemoInterface, WriterInterface } from "@carglassgithub/memod-sdk";
import { translate } from "@locales";
import { sdk, sdkv1 } from "@models/api";
import { UserAtom, UserSaveItemsAtom, MemoSaveAtom } from "@models/state";
import {
  AUTH_STATUS,
  AuthModalAtom,
} from "@models/state/atoms/auth-modal.atom";
import { UserLoggedAtom } from "@models/state/atoms/user-logged.atom";
import useAmplitude from "@models/interactions/use-amplitude";
import { useMemoCover } from "@models/interactions/use-memo-cover";
import {
  interpolatedText,
  interpolatedTextString,
} from "@models/interactions/interpolate-text";
import DownDrop, { OptionInterface } from "@components/atoms/drop-down";
import Icon from "@components/atoms/icon";
import CreateCollectionModal from "@components/molecules/create-collection-modal";
import SaveMemoPopover, {
  SaveMemoItem,
} from "@components/molecules/save-memo-popover";
import ReportMemoModal from "@components/organisms/report-memo-modal";
import ShareCardModal from "../../molecules/share-card-modal";
import UserInformation from "../../molecules/user-information";
import Topic from "../../molecules/topic";
import { resizeImage } from "@models/interactions/utils";

type Props = {
  variant: Variants;
  memo: MemoInterface;
  writer?: WriterInterface;
  className?: CSSClasses;
  isAuthor?: boolean;
  isPrivate?: boolean;
  menuOptions?: OptionInterface[];
  onDelete?: (id: number) => void;
};

type CSSClasses = {
  container?: string;
  header?: string;
  content?: string;
  title?: string;
  image?: string;
  footer?: string;
};

type Variants = "simple" | "detailed";

const cookies = Cookies();

const variantClasses = {
  simple: {
    container: "py-3",
    header: "",
    content: "",
    title: "lg:text-xl lg:leading-8",
    image: "w-[100px] h-[68px] lg:w-[118px] lg:h-20",
    footer: "",
  },
  detailed: {
    container: "py-4",
    header: "h-8 mb-1",
    content: "xl:gap-4 mb-4",
    title: "md:text-xl md:leading-8",
    image: "w-[100px] h-[68px] md:w-[178px] md:h-[116px]",
    footer: "!text-sm",
  },
};

const defaultMemoImage = {
  url: "/img/defaults/memo-placeholder.jpg",
  alt: "",
};

const memoStatusIcon = [
  <Icon.Edit size={20} fill="currentColor" />,
  <Icon.GlobeFill size={20} />,
];

type SaveItemPopoverProps = {
  children?: JSX.Element;
  className?: string;
  position?: "top" | "bottom" | "left";
  items: any[];
  count: string | number;
  isLoading?: boolean;
  active: boolean;
  showCount?: boolean;
  action: () => void;
  itemClicked: (item: SaveMemoItem) => void;
};

function SaveItemPopover(props: SaveItemPopoverProps) {
  const { showCount = true } = props;

  return (
    <SaveMemoPopover
      items={props.items}
      onItem={props.itemClicked}
      className="w-full flex-1 !justify-start gap-1.5"
      panelClassName="z-10"
      position={props.position}
      showLoading={true}
      isLoading={props.isLoading}
    >
      <div
        className={classNames("flex items-center gap-1.5", props.className)}
        onClick={() => props.action()}
      >
        {props.children}
        <Icon.Save size={18} active={props.active} />
        {showCount && (
          <span className="min-w-[10px] text-right">{props.count}</span>
        )}
      </div>
    </SaveMemoPopover>
  );
}

function useMemoCard(props: Props) {
  const router = useRouter();
  const { operations: amplitude } = useAmplitude();
  const { cover } = useMemoCover(props.memo.files);
  const store = useStore();

  const isUserLoggedIn = useAtomValue(UserLoggedAtom);
  const user = useAtomValue(UserAtom);
  const boards = useAtomValue(UserSaveItemsAtom).sort((a, b) =>
    a.text.localeCompare(b.text)
  );
  const [showShareModal, toggleShowShareModal] = useReducer(
    (state) => !state,
    false
  );
  const [showReportModal, toggleShowReportModal] = useReducer(
    (state) => !state,
    false
  );
  const [isPublished, setIsPublished] = useState(props.memo.is_published);
  const [isPublic, setIsPublic] = useState(props.memo.is_public);
  const [totalLikes, setTotalLikes] = useState(props.memo.total_likes);
  const [totalSaved, setTotalSaved] = useState(props.memo.total_saved);
  const [liked, setLiked] = useState(props.memo.is_liked === 1);
  const [saved, setSaved] = useState(props.memo.is_saved === 1);
  const [fetchingBoards, setFetchingBoards] = useState(false);
  const [showCreate, setShowCreate] = useState(false);

  const className = useMemo(() => {
    if (props.variant) {
      return variantClasses[props.variant as keyof typeof variantClasses];
    }

    return {} as CSSClasses;
  }, []);

  const writer = useMemo(() => {
    return props?.writer ?? props.memo.writer;
  }, []);

  const memoImage = useMemo(() => {
    if (cover) {
      return {
        url: cover?.attributes?.unique_name
          ? resizeImage(cover.attributes.unique_name, "200")
          : cover.url,
        alt: cover.name,
      };
    }

    return defaultMemoImage;
  }, []);

  async function getBoards() {
    if (fetchingBoards) return;

    if (!user) {
      amplitude.send("Saved Entity", {
        saved_at: Date.now(),
        entity_owner_id: props.memo.writer.id,
        entity_id: props.memo.id,
        entity_name: props.memo?.title,
        entity_type: "memo",
      });

      store.set(AuthModalAtom, {
        mode: AUTH_STATUS.LOGIN,
        open: true,
      });
      return;
    }

    if (boards.length > 1) {
      return;
    }

    setFetchingBoards(true);

    const { data: boardsList } = await sdkv1.memodUsers.getBoards(user.id, {
      limit: 25,
      page: 1,
      memo_id: props.memo.id,
    });

    const mappedBoards = boardsList
      .map<SaveMemoItem>((board) => ({
        key: board.id,
        text: board.list_name,
        saved: board.is_inside === 1,
      }))
      .filter((board) => board.text !== "Unlocked Memos");

    store.set(UserSaveItemsAtom, (current) => [
      ...current,
      ...mappedBoards.reverse(),
    ]);
    setFetchingBoards(false);
  }

  const handleLike = async () => {
    amplitude.send(props.memo.is_liked ? "Removed Like Entity" : "Liked Entity", {
      [props.memo.is_liked ? "remove_like_at" : "like_at"]: Date.now(),
      parent_id: props.memo.writer.id,
      entiy_name: props.memo?.title,
      entity_id: props.memo.id,
      entity_type: "memo",
    });

    if (isUserLoggedIn) {
      const data = await sdkv1.feeds.likeMemo(props.memo.id);
      setTotalLikes(data.total);
      setLiked(data.is_liked === 1);
    } else {
      store.set(AuthModalAtom, {
        mode: AUTH_STATUS.SIGN_UP,
        open: true,
      });
    }
  };

  const handleSaved = async (item: SaveMemoItem) => {
    if (isUserLoggedIn) {
      if (item.text === "Save to new collection") {
        toggleCreate();
        return;
      }

      store.set(UserSaveItemsAtom, (current) => {
        const index = current.findIndex((i) => i.key === item.key);
        current[index] = { ...item, loading: true };
        return [...current];
      });

      const response = await sdkv1.feeds.save({
        list_id: item.key,
        storm_id: props.memo.id,
      });

      setSaved(response.is_saved === 1);

      amplitude.send(
        response.is_saved ? "Saved Entity" : "Remove Saved Entity",
        {
          [response.is_saved ? "saved_at" : "remove_saved_at"]: Date.now(),
          entity_owner_id: props.memo.writer.id,
          entity_id: props.memo.id,
          entity_name: props.memo?.title,
          entity_type: "memo",
        }
      );

      toast(
        response.is_saved
          ? translate("library.memos.saved-collection")
          : translate("library.memos.removed-collection"),
        { icon: <Icon.NotificationCheck /> }
      );

      store.set(UserSaveItemsAtom, (current) => {
        const index = current.findIndex((i) => i.key === item.key);
        current[index] = {
          ...item,
          loading: false,
          saved: response.is_inside === 1,
        };

        store.set(MemoSaveAtom, !!current.find((i) => i.saved));
        return [...current];
      });

      setTotalSaved(response.memo_total_saved);
    } else {
      amplitude.send("Unknown User Clicked Component", {
        entity_id: props.memo.id,
        entity_type: "memo",
        entity_name: props.memo?.title,
        entity_owner_id: props.memo.writer.id,
        user_id: amplitude.getUserId,
        unknown_user_id: amplitude.getUserId,
        path: router.asPath,
        click_at: Date.now(),
      });
      store.set(AuthModalAtom, {
        mode: AUTH_STATUS.SIGN_UP,
        open: true,
      });
    }
  };

  const toggleCreate = () => {
    if (user) {
      amplitude.send("Clicked Component", {
        name: "clicked-create-collection",
        title: "Clicked Create Collection",
        user_id: user.id,
        path: router.asPath,
        click_at: Date.now(),
      });
    }
    setShowCreate(!showCreate);
  };

  const copyLink = () => {
    if (navigator) {
      navigator.clipboard.writeText(
        `${window.location.origin}/${writer.displayname}/${props.memo.slug}-${props.memo.id}`
      );
      toast("Link copied", { icon: <Icon.NotificationCheck /> });
    }
  };

  const editMemo = () => {
    router.push(`/edit/${props.memo.slug}-${props.memo.id}`);
  };

  const publish = async () => {
    try {
      const { data } = await sdk.http.http.put(
        `/memos/${props.memo.id}/status`,
        {
          is_published: Number(!isPublished),
        },
        {
          headers: {
            authorization: cookies.get("token"),
          },
        }
      );

      setIsPublished(data.is_published);

      const message = interpolatedTextString(
        "base.status-memo",
        "",
        "published"
      );

      toast([message].flat().join(""), {
        icon: <Icon.NotificationCheck />,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const togglePublic = async () => {
    try {
      const { data } = await sdk.http.http.put(
        `/memos/${props.memo.id}/status`,
        {
          is_public: Number(!isPublic),
        },
        {
          headers: {
            authorization: cookies.get("token"),
          },
        }
      );

      setIsPublic(data.is_public);

      const status = translate("base.collection-state")[
        data.is_public
      ].toLocaleLowerCase();
      const message = interpolatedTextString(
        "base.status-memo",
        "",
        [translate("base.item-status", { interpolate: { status } })]
          .flat()
          .join("")
      );

      toast([message].flat().join(""), {
        icon: <Icon.NotificationCheck />,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteMemo = async () => {
    try {
      await sdk.http.http.delete(`/memos/${props.memo.id}`, {
        headers: {
          authorization: cookies.get("token"),
        },
      });

      props.onDelete?.(props.memo.id);
    } catch (e) {
      console.log(e);
    }
  };

  const memoUserOptions: OptionInterface[] = [
    {
      text: "Share",
      icon: <Icon.Share size={20} />,
      onClick: () => toggleShowShareModal(),
    },
    {
      text: "Copy link",
      icon: <Icon.Link size={24} />,
      onClick: () => copyLink(),
    },
    {
      text: "Report",
      icon: <Icon.Flag size={24} />,
      className: "text-memod-error-base",
      onClick: () => toggleShowReportModal(),
    },
  ];

  const memoAuthorOptions: OptionInterface[] = [
    ...((isPublished && [
      {
        text: "Share",
        icon: <Icon.Share size={20} />,
        onClick: () => toggleShowShareModal(),
      },
      {
        text: "Copy link",
        icon: <Icon.Link size={24} />,
        onClick: () => copyLink(),
      },
    ]) ||
      []),
    {
      text: "Edit Memo",
      icon: <Icon.Edit size={20} />,
      onClick: () => editMemo(),
    },
    // @ts-ignore
    ...((isPublished && [
      {
        text: "",
        icon: (
          <SaveItemPopover
            items={boards}
            count={totalSaved}
            isLoading={fetchingBoards}
            className="grow justify-between"
            active={saved}
            showCount={false}
            action={getBoards}
            itemClicked={handleSaved}
          >
            <span>Move to Collection</span>
          </SaveItemPopover>
        ),
      },
      {
        text: translate("base.make-private")[isPublic],
        icon: <Icon.Lock size={20} />,
        onClick: () => togglePublic(),
      },
    ]) ||
      []),
    ...((!isPublished && [
      {
        text: "Publish",
        icon: <Icon.GlobeFill size={20} />,
        onClick: () => publish(),
      },
    ]) ||
      []),
    {
      text: "Delete",
      icon: <Icon.Trash size={24} />,
      className: "text-memod-error-base",
      onClick: () => deleteMemo(),
    },
  ];

  const menuOptions = useMemo(() => {
    const options = props.isAuthor ? memoAuthorOptions : memoUserOptions;

    return [...(props.menuOptions || []), ...options];
  }, [props.isAuthor, props.menuOptions]);

  return {
    models: {
      className,
      writer,
      memoImage,
      isPublished,
      isPublic,
      showShareModal,
      isUserLoggedIn,
      totalLikes,
      totalSaved,
      liked,
      saved,
      fetchingBoards,
      boards,
      showCreate,
      showReportModal,
      menuOptions,
    },
    operations: {
      toggleShowShareModal,
      getBoards,
      handleLike,
      handleSaved,
      toggleCreate,
      toggleShowReportModal,
    },
  };
}

export default function MemoCard(props: Props) {
  const { models, operations } = useMemoCard(props);

  return (
    <div
      className={classNames(
        "flex grow flex-col border-b border-memod-separator last:border-0",
        models.className.container,
        props.className?.container
      )}
    >
      {models.showShareModal && (
        <ShareCardModal
          open
          memo={props.memo}
          writer={models.writer}
          onClose={operations.toggleShowShareModal}
        />
      )}
      {models.showCreate && (
        <CreateCollectionModal
          open
          memo={props.memo}
          setToggleCreateModal={operations.toggleCreate}
        />
      )}
      {models.showReportModal && (
        <ReportMemoModal
          open
          memoId={props.memo.id}
          onClose={operations.toggleShowReportModal}
        />
      )}
      <div
        className={classNames(
          "mb-2 flex items-center justify-between",
          models.className.header
        )}
      >
        {props.isPrivate && (
          <div className="flex items-center gap-1 text-[#99999F]">
            {models.isPublic ? (
              <>
                {memoStatusIcon[models.isPublished]}
                {translate("base.memo-card-state")[models.isPublished]}
              </>
            ) : (
              <>
                <Icon.Lock size={20} stroke="currentColor" />
                {translate("library.private-memo")}
              </>
            )}
          </div>
        )}
        {!props.isPrivate && (
          <UserInformation
            writer={models.writer}
            size={"default"}
            avatarSize={20}
            className="!block items-center"
            linkClassName="gap-1"
            textClassName="text-composer-text/60 md:leading-5 xl:text-base"
          />
        )}
        {props.variant === "detailed" && (
          <DownDrop
            iconButton={
              <span className="flex h-6 w-6 items-center justify-center">
                <Icon.ThreeDots size={24} fill="#FFF" />
              </span>
            }
            options={models.menuOptions}
            optionClassName="items-center"
          />
        )}
      </div>
      <Link
        href={`/${models.writer.displayname}/${props.memo.slug}-${props.memo.id}`}
        className={classNames("flex grow gap-3", models.className.content)}
      >
        <div className="flex h-fit grow flex-col gap-1 text-memod-white-base">
          <h3
            className={classNames(
              "break-word line-clamp-2 font-bold",
              models.className.title
            )}
          >
            {props.memo.title}
          </h3>
          {models.isPublished && props.variant === "detailed" ? (
            <p className="break-word line-clamp-3 text-sm md:line-clamp-2 md:text-base">
              {props.memo.description}
            </p>
          ) : null}
        </div>
        <img
          src={models.memoImage.url}
          className={classNames("rounded object-cover", models.className.image)}
          alt={models.memoImage.alt}
          loading="lazy"
        />
      </Link>
      <div className="flex justify-between text-sm">
        {props.isPrivate && !models.isPublished ? (
          <div className="flex items-center gap-2 text-memod-label">
            {translate("library.unpublished-memo")}
          </div>
        ) : null}
        {!props.isPrivate && (
          <>
            <div
              className={classNames(
                "flex items-center gap-3 text-memod-label",
                models.className.footer
              )}
            >
              {props.variant === "detailed" &&
                props.memo.topics &&
                props.memo.topics[0] && (
                  <Topic
                    text={props.memo.topics[0].name}
                    slug={props.memo.topics[0].slug}
                    className="leading-4"
                  />
                )}
              <div>
                {interpolatedText("base.read", props.memo.total_reading_time)}
              </div>
            </div>
            {props.variant === "detailed" && (
              <div className="flex items-center gap-3 text-white">
                <div
                  className="flex cursor-pointer items-center gap-1.5"
                  onClick={() => operations.handleLike()}
                >
                  <Icon.Like size={20} active={models.liked} />
                  <span className="min-w-[10px] text-right">
                    {models.totalLikes}
                  </span>
                </div>
                <div>
                  <SaveItemPopover
                    items={models.boards}
                    count={models.totalSaved}
                    isLoading={models.fetchingBoards}
                    active={models.saved}
                    position="top"
                    action={operations.getBoards}
                    itemClicked={operations.handleSaved}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
