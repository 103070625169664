import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
  Icon?: ReactNode;
  text: string;
  active?: boolean;
  onClick?: VoidFunction;
  className?: string;
}

export default function SaveMemoItem(props: Props) {
  return (
    <div
      className={classNames(
        "flex cursor-pointer flex-row items-center gap-2 p-2 hover:text-memod-reader-hover",
        props.className,
        {
          "text-[#9A9AA0]": !props.active,
          "text-memod-green-base": props.active,
        }
      )}
      onClick={props.onClick}
    >
      <span className="flex-1">{props.text}</span>
      {props.Icon && props.Icon}
    </div>
  );
}
