import Icon from "@components/atoms/icon";
import Loading from "@components/atoms/loading";
import Popover, { Item } from "@components/atoms/popover";
import SaveMemoItem from "@components/atoms/save-memo-item";
import classNames from "classnames";
import { createElement, PropsWithChildren } from "react";

export interface SaveMemoItem extends Item {
  loading?: boolean;
  saved?: boolean;
}

interface Props extends PropsWithChildren {
  className?: string;
  panelClassName?: string;
  items?: SaveMemoItem[];
  onItem?: (item: SaveMemoItem) => void;
  position?: "top" | "bottom" | "left";
  showLoading?: boolean;
  isLoading?: boolean;
}

export function useSaveMemoPopover() {
  const getIcon = (item: SaveMemoItem) => {
    if (item.Icon) {
      return createElement(item.Icon, { size: 16 });
    }

    if (item.loading) {
      return <Loading />;
    }

    if (item.saved) {
      return <Icon.Saved size={16} />;
    }

    return null;
  };

  return {
    model: {},
    operations: {
      getIcon,
    },
  };
}

export default function SaveMemoPopover(props: Props) {
  const { model, operations } = useSaveMemoPopover();
  
  return (
      <Popover
        {...props}
        onRenderItem={(item: SaveMemoItem) => (
          <SaveMemoItem
            {...item}
            key={item.key}
            active={item.saved}
            onClick={() => props.onItem?.(item)}
            Icon={operations.getIcon(item)}
            className={classNames(
              "save-popover hover:bg-[#5a5a5d]",
              props.className,
            )}
          />
        )}
      />
  );
}
