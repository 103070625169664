import Link from "next/link";
import classNames from "classnames";

interface Props {
  text: string;
  slug: string;
  className?: string;
}

export default function Topic(props: Props) {
  return (
    <Link
      className={classNames(
        "rounded-full bg-memod-topic-base py-2 px-3 text-memod-topic-label",
        props.className,
      )}
      href={`/topics/${props.slug}`}
    >
      {props.text}
    </Link>
  );
}
